import styled, { useTheme } from 'styled-components'
import { motion, HTMLMotionProps } from 'framer-motion'

const Button = styled(motion.button)`
  position: relative;
  padding: ${props => props.theme.padding.icon};
  display: flex;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius.current};

  border: transparent;
  background-color: transparent;

  outline-color: transparent;
  transition: 0.4s ease-in outline-color;

  &:hover,
  &:focus,
  &:focus-within,
  &:focus-visible {
    background-color: transparent;
    outline: ${props => props.theme.border.button} solid ${props => props.theme.colors.primary};
  }

  &:hover > svg,
  &:focus > svg,
  &:focus-within > svg {
    fill: ${props => props.theme.colors.secondary};
  }
`

const IconButton: React.FC<IconButtonProps> = ({
  icon: Icon,
  title,
  color,
  style,
  size = 24,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Button
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.97 }}
      title={title}
      style={style}
      {...props}
    >
      <Icon fill={color || theme.colors.primary} width={size} height={size} />
    </Button>
  )
}

export interface IconButtonProps extends HTMLMotionProps<'button'> {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  title?: string
  color?: string
  size?: string | number
  style?: React.CSSProperties
}

export default IconButton
