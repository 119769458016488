import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Internationalization: React.FC = () => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language)
    document.title = t('Shareadesk - Find your next desk and connect')
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        'content',
        t(
          'Share a desk is a service designed to connect people willing to work remotely and share a work experience.'
        )
      )

    // OpenGraph
    document.querySelector('meta[property="og:locale"]')?.setAttribute('content', i18n.language)
    document
      .querySelector('meta[property="og:title"]')
      ?.setAttribute('content', t('Shareadesk - Find your next desk and connect'))
    document
      .querySelector('meta[property="og:description"]')
      ?.setAttribute(
        'content',
        t(
          'Share a desk is a service designed to connect people willing to work remotely and share a work experience.'
        )
      )

    // Twitter
    document
      .querySelector('meta[name="twitter:title"]')
      ?.setAttribute('content', t('Shareadesk - Find your next desk and connect'))
    document
      .querySelector('meta[name="twitter:description"]')
      ?.setAttribute(
        'content',
        t(
          'Share a desk is a service designed to connect people willing to work remotely and share a work experience.'
        )
      )
    document
      .querySelector('meta[name="twitter:image:alt"]')
      ?.setAttribute('content', t('Connection'))
  }, [t, i18n.language])

  return null
}

export default Internationalization
