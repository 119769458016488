import { useState } from 'react'
import constate from 'constate'

import { ModalHanlde } from '../app/components/Modal'
import { OSMData } from '../app/components/Input'

const useStore = () => {
  const useProspectModalRef = useState<ModalHanlde | null>(null)
  const useSearchLocation = useState<OSMData>()

  return { useProspectModalRef, useSearchLocation }
}

const [StoreProvider, useStoreContext] = constate(useStore)

export { StoreProvider, useStoreContext }
