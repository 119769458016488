import { useState, useEffect, useMemo, forwardRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { usePopper } from 'react-popper'
import debounce from 'lodash/debounce'
import { ReactComponent as LoopSvg } from '@material-design-icons/svg/round/autorenew.svg'

import { InlineFlex } from '../Layout'
import Button from '../Button'
import PrimaryInput from './PrimaryInput'
import { InputProps } from './Input'

const RelativePos = styled.div`
  position: relative;
  width: 0;
  height: 0%;
`
const LoopIcon = styled(LoopSvg)`
  animation: rotating 2s linear infinite;
  position: absolute;
  left: -2.188rem;
  top: -0.688rem;
  opacity: 0.5;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.boxShadow.current};
  padding: 0.625rem;
  border-radius: ${props => props.theme.borderRadius.current};

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  z-index: 1;
`

interface PopupProps {
  results: OSMData[]
  callback: ((result: OSMData) => void) | undefined
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>
  referenceElement: HTMLElement | null
}
const PopupResults: React.FC<PopupProps> = ({
  results,
  referenceElement,
  callback,
  setShowResults,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  })

  return createPortal(
    <Container ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      {results.map((result: OSMData, index: number) => (
        <Button
          key={`osm-result-${index}`}
          type="button"
          onClick={() => {
            if (callback) {
              callback(result)
              setShowResults(false)
            }
          }}
        >
          {result?.display_name}
        </Button>
      ))}
      <div ref={setArrowElement} style={styles.arrow} />
    </Container>,
    document.getElementById('root')!
  )
}

const LocationInput = forwardRef<HTMLInputElement, LocationInputProps>(
  ({ value, getValue = (data: OSMData): unknown => data, ...props }, ref) => {
    const { i18n } = useTranslation()
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
    const [results, setResults] = useState<OSMData[]>([])
    const [showResults, setShowResults] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [innerValue, setInnerValue] = useState(value?.display_name)

    useEffect(() => {
      const onClickEvent = (event: MouseEvent) => {
        const isClickInside = referenceElement?.contains(event.target as Node)
        if (!isClickInside) {
          setShowResults(false)
        }
      }

      document.addEventListener('click', onClickEvent)

      return () => {
        document.removeEventListener('click', onClickEvent)
      }
    }, [referenceElement])

    useEffect(() => {
      props.onChange?.({
        target: { name: props.name || '', value: value ? getValue(value) : value },
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const debouncer = useMemo(
      () =>
        debounce((lng: string, search?: string) => {
          if (!search) {
            setShowLoader(false)
            setResults([])
            return
          }

          setShowLoader(true)

          const url = `https://nominatim.openstreetmap.org/search?format=json&q=${search}&accept-language=${lng}`

          fetch(url)
            .then(response => response.json())
            .then((data: Array<OSMData>) => {
              setResults(data)
              setShowResults(true)
            })
            .catch(err => console.warn(err))
            .finally(() => setShowLoader(false))
        }, 1000),
      []
    )

    const callback = (result: OSMData) => {
      setInnerValue(result.display_name)
      props.onChange?.({
        target: { name: props.name || '', value: getValue(result) },
      })
    }

    return (
      <InlineFlex ref={setReferenceElement}>
        <PrimaryInput
          name="geocoding"
          id="geocoding"
          title={
            typeof innerValue === 'string' && innerValue
              ? `${props.placeholder ? `${props.placeholder}: ` : ''}${innerValue}`
              : props.placeholder
          }
          {...props}
          ref={ref}
          value={innerValue}
          type="text"
          onChange={event => {
            setShowLoader(true)
            debouncer(i18n.language, event.target.value)
          }}
          onClick={event => {
            setShowResults(true)
            props.onClick?.(event)
          }}
          onKeyUp={event => {
            if (event.key === 'Escape') {
              setShowResults(false)
            }

            props.onKeyUp?.(event)
          }}
        />
        {showLoader && (
          <RelativePos>
            <LoopIcon />
          </RelativePos>
        )}
        {!!results.length && !showLoader && showResults && (
          <PopupResults
            referenceElement={referenceElement}
            results={results}
            callback={callback}
            setShowResults={setShowResults}
          />
        )}
      </InlineFlex>
    )
  }
)

LocationInput.displayName = 'LocationInput'

export interface LocationInputProps extends Omit<InputProps, 'onChange' | 'value'> {
  placeholder?: string
  waitDebounce?: number
  callback?: (result: OSMData) => void
  city?: string
  countrycodes?: string
  acceptLanguage?: string
  viewbox?: string
  getValue?: (data: OSMData) => unknown
  onChange?: (event: {
    target: {
      name: string
      value: unknown
    }
  }) => void
  value?: OSMData
}

export type OSMData = {
  boundingbox: string[]
  display_name: string
  lat: string
  lon: string

  osm_id: number
  osm_type: string
  place_id: number
  type: string
  class: string
  icon: string
  importance: number

  licence: string
}

export default LocationInput
