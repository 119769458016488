import { useRef, useImperativeHandle, forwardRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Modal, { ModalHanlde } from '../../components/Modal'
import Button from '../../components/Button'
import { FlexResponsiveSection, Flex } from '../../components/Layout'

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`
const RegionSubtitle = styled.p`
  margin: 0;
  font-weight: 300;
`

const LocalizationModal = forwardRef<Pick<LocalizationHanlde, 'openModal'>>((_props, ref) => {
  const modalRef = useRef<ModalHanlde>(null)
  const { i18n, t } = useTranslation()

  useImperativeHandle(
    ref,
    () => ({
      openModal: () => {
        modalRef.current?.openModal()
      },
    }),
    []
  )

  return (
    <Modal
      style={{
        content: {
          margin: 'auto',
          width: '50%',
        },
      }}
      ref={modalRef}
    >
      <FlexResponsiveSection>
        <Flex>
          <h2>{t('Language and region')}</h2>
          <FlexSection>
            <Button
              width="12.5rem"
              onClick={() => {
                void i18n.changeLanguage('en-GB')
                document.location.reload()
              }}
            >
              English
              <RegionSubtitle>United Kingdom</RegionSubtitle>
            </Button>
            <Button
              width="12.5rem"
              onClick={() => {
                void i18n.changeLanguage('fr-FR')
                document.location.reload()
              }}
            >
              Français
              <RegionSubtitle>France</RegionSubtitle>
            </Button>
          </FlexSection>
        </Flex>
      </FlexResponsiveSection>
    </Modal>
  )
})

LocalizationModal.displayName = 'LocalizationModal'

export type LocalizationHanlde = Pick<ModalHanlde, 'openModal'>

export default LocalizationModal
