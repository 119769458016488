import { createGlobalStyle } from 'styled-components'

import { useMobileMediaQuery, useMobileXSMediaQuery } from '../hooks'

const Styles = createGlobalStyle<{ isMobileXS?: boolean; isMobile?: boolean }>`
  :root {
    font-size: ${props => props.theme.fontSizes.root};

    --toastify-color-success: ${props => props.theme.colors.success};
    --toastify-color-warning: ${props => props.theme.colors.warn};
    --toastify-color-error: ${props => props.theme.colors.error};
  }

  html {
    font-size: ${props => (props.isMobileXS ? '60%' : props.isMobile ? '75%' : '100%')};
  }

  body {
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.colors.light};
    font-family: ${props => props.theme.fonts[0]};
    font-size: ${props => props.theme.fontSizes.root};
    color: ${props => props.theme.colors.dark};
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: ${props => props.theme.fontSizes.xl};
    font-weight: bold;
    line-height: 1.2em;
    color: ${props => props.theme.colors.secondary};
    margin: 1.5rem 0;
  }
  h2 {
    font-size: ${props => props.theme.fontSizes.l};
    color: ${props => props.theme.colors.secondary};
    margin: 1.5rem 0;
  }
  h3 {
    font-size: ${props => props.theme.fontSizes.m};
    color: ${props => props.theme.colors.secondary};
    margin: 1rem 0;
  }
  h4 {
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.secondary};
    margin: 1rem 0;
  }

  p {
    font-size: ${props => props.theme.fontSizes.s};
    line-height: 1.5em;
    color: ${props => props.theme.colors.grey};
  }

  article {
    p {
      font-size: ${props => props.theme.fontSizes.sm};
    }
  }

  svg {
    color: ${props => props.theme.colors.primary};
  }

  a {
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.grey};
    font-weight: bold;

    &:hover,
    &:focus,
    &:focus-within {
      color: ${props => props.theme.colors.primary};
    }
    &:focus-visible{
      outline: ${props => props.theme.border.link} solid ${props => props.theme.colors.primary};
      border-radius: ${props => props.theme.borderRadius.link};
    }
  }

  .Toastify__close-button svg {
    color: black;
  }

  .cc-window {
    box-shadow: ${props => props.theme.boxShadow.current};
    font-family: ${props => props.theme.fonts[0]};
    font-size: ${props => props.theme.fontSizes.s};

    .cc-link {
      padding: 0;
    }
  }
`

const GlobalStyles: React.FC = () => {
  const isMobile = useMobileMediaQuery()
  const isMobileXS = useMobileXSMediaQuery()

  return <Styles isMobile={isMobile} isMobileXS={isMobileXS} />
}

export default GlobalStyles
