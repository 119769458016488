import { motion, HTMLMotionProps } from 'framer-motion'
import styled from 'styled-components'
import { Property } from 'csstype'

const Styled = styled(motion.button)<{ width?: Property.Width }>`
  background-color: ${props => props.theme.colors.light};
  color: ${props => props.theme.colors.dark};

  border: 0;
  border-radius: ${props => props.theme.borderRadius.button};
  padding: ${props => props.theme.padding.button};
  width: ${props => props.width};

  font-family: ${props => props.theme.fonts[0]};
  font-size: ${props => props.theme.fontSizes.s};
  font-weight: 500;

  text-align: center;
  cursor: pointer;

  transition: 0.2s ease-in-out background-color;

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    outline: none;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.grey};
  }

  svg {
    fill: ${props => props.theme.colors.white};
  }
  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible {
    svg {
      fill: ${props => props.theme.colors.primary};
    }
  }
`

const Button: React.FC<ButtonProps> = ({ children, width, ...props }) => (
  <Styled {...props} width={width} whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }}>
    {children}
  </Styled>
)

export interface ButtonProps extends HTMLMotionProps<'button'> {
  width?: Property.Width
}

export default Button
