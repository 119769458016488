import Input from './Input'

export { default as PrimaryInput } from './PrimaryInput'
export { default as SearchInput } from './SearchInput'
export { default as LocationInput } from './LocationInput'
export * from './Input'
export * from './LocationInput'
export * from './SearchInput'

export default Input
