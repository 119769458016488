import { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { useTheme } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { toast } from 'react-toastify'
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Modal, { ModalHanlde } from '../../components/Modal'
import { PrimaryButton, IconButton } from '../../components/Button'
import Input, { LocationInput, OSMData } from '../../components/Input'
import SegmentedControl from '../../components/SegmentedControl'
import { FlexResponsiveSection, Flex, InlineFlex } from '../../components/Layout'
import i18n from '../../../i18n'
import { useStoreContext } from '../../../context/store'
import { ReactComponent as CoworkingSvg } from '../../../assets/undraw_pair_programming_njlp.svg'
import { ReactComponent as FacebookSvg } from '../../../assets/brands/facebook-square-brands.svg'
import { ReactComponent as InstagramSvg } from '../../../assets/brands/instagram-brands.svg'
import { ReactComponent as LinkedinSvg } from '../../../assets/brands/linkedin-brands.svg'
import { ReactComponent as TwitterSvg } from '../../../assets/brands/twitter-brands.svg'
import { useMobileMediaQuery } from '../../../hooks'

const Title = styled.h1`
  text-align: right;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & p {
    margin: 0;
  }
`
const CenteredFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: center;
`

export type ProspectModalHandle = Pick<ModalHanlde, 'openModal'>

const schema = yup
  .object({
    email: yup
      .string()
      .email(i18n.t('A valid email is required.'))
      .required(i18n.t('This field is required.')),
    lookingFor: yup.string().required(i18n.t('This field is required.')),
    location: yup.lazy((value: unknown) => {
      switch (typeof value) {
        case 'object':
          return yup
            .object({
              display_name: yup.string(),
              osm_id: yup.number(),
            })
            .nullable()
            .required(i18n.t('This field is required.'))
        case 'string':
          return yup.string().nullable().required(i18n.t('This field is required.'))
        default:
          return yup.mixed().required(i18n.t('This field is required.'))
      }
    }),
  })
  .required()

export interface FormValues {
  email: string
  lookingFor: string
  location: OSMData | string
}

const ADD_PROSPECT = gql`
  mutation AddProspect($email: String!, $lookingFor: String!, $location: String!, $osmId: Int!) {
    addProspect(email: $email, lookingFor: $lookingFor, location: $location, osmId: $osmId) {
      email
      lookingFor
      location
      osmId
    }
  }
`

const ProspectModal: React.FC = () => {
  const {
    useProspectModalRef: [prospectModalRef, setProspectModalRef],
    useSearchLocation: [locationStored],
  } = useStoreContext()
  const [submited, setSubmited] = useState(false)
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()
  const { t } = useTranslation()

  const shareText = t(
    "Shareadesk, the first service designed to connect people willing to work remotely and share a work experience. 🚀 You'll love remote!"
  )

  const [addProspect] = useMutation(ADD_PROSPECT, {
    onError: () => {
      toast.error(t('Invalid submission'))
    },
    onCompleted: () => {
      toast.success(t('Submission succeed'))
      setSubmited(true)
    },
  })

  const { register, formState, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })
  const { errors } = formState

  const onSubmit: SubmitHandler<FormValues> = ({ email, lookingFor, location }) => {
    void addProspect({
      variables: {
        lookingFor,
        email,
        location: typeof location === 'string' ? location : location?.display_name,
        osmId: typeof location === 'string' ? 0 : location?.osm_id,
      },
    })
  }
  const onError: SubmitErrorHandler<FormValues> = () => {
    toast.error(t('Invalid submission'))
  }

  return (
    <>
      <PrimaryButton
        onClick={() => {
          prospectModalRef?.openModal()
        }}
      >
        {t('Learn More')}
      </PrimaryButton>

      <Modal
        ref={setProspectModalRef}
        style={{
          content: {
            width: isMobile ? '100%' : '80%',
          },
        }}
      >
        <FlexResponsiveSection>
          <Flex>
            <CoworkingSvg width="100%" height="auto" title={t('Coworking')} />
          </Flex>
          <Flex minWidth="15rem">
            <Title>
              <Trans>
                Still working hard,
                <br />
                come back soon.
              </Trans>
            </Title>

            {submited ? (
              <CenteredFlex>
                <Trans>
                  <p>Thank you for your submission.</p>
                  <p>We will reach back to you later.</p>
                </Trans>
              </CenteredFlex>
            ) : (
              <Form onSubmit={handleSubmit(onSubmit, onError)}>
                <p>{t('Share your email with us to keep in touch.')}</p>
                <Input
                  placeholder={t('Email')}
                  error={errors.email}
                  required
                  {...register('email')}
                />

                <p>{t('Your location:')}</p>
                <LocationInput
                  placeholder={t('Location')}
                  error={errors.location}
                  style={{ backgroundColor: theme.colors.light }}
                  required
                  getValue={({ display_name, osm_id }) => ({
                    display_name,
                    osm_id,
                  })}
                  value={locationStored}
                  {...register('location')}
                />

                <p>{t("I'm looking for:")}</p>
                <SegmentedControl
                  register={() => register('lookingFor')}
                  options={[
                    {
                      label: t('Find a desk'),
                      shortLabel: t('Desk'),
                      value: 'finder',
                      default: true,
                    },
                    {
                      label: t('Become a host'),
                      shortLabel: t('Host'),
                      value: 'host',
                    },
                    {
                      label: t('Both'),
                      shortLabel: t('Both'),
                      title: t('Find a desk and become a host'),
                      value: 'both',
                    },
                  ]}
                />

                <p />
                <PrimaryButton type="submit">{t('Submit')}</PrimaryButton>
              </Form>
            )}

            <Aside style={{ marginTop: '3rem' }}>
              <InlineFlex>
                <p>{t('Share to a friend:')}</p>&nbsp;
                <IconButton
                  icon={FacebookSvg}
                  onClick={() => {
                    window.open(
                      `https://www.facebook.com/sharer/sharer.php?u=https://shareadesk.eu&quote=${encodeURI(
                        shareText
                      )}`,
                      '_blank'
                    )
                  }}
                />{' '}
                <IconButton
                  icon={InstagramSvg}
                  onClick={() => {
                    window.open(`https://www.instagram.com/shareadesk/`, '_blank')
                  }}
                />{' '}
                <IconButton
                  icon={LinkedinSvg}
                  onClick={() => {
                    window.open(
                      `https://www.linkedin.com/shareArticle?mini=true&url=https://shareadesk.eu`,
                      '_blank'
                    )
                  }}
                />{' '}
                <IconButton
                  icon={TwitterSvg}
                  onClick={() => {
                    window.open(
                      `https://twitter.com/intent/tweet?text=${encodeURI(
                        shareText
                      )} https://shareadesk.eu&hashtags=coworking,remote,shareadesk`,
                      '_blank'
                    )
                  }}
                />
              </InlineFlex>
              <InlineFlex>
                <p>{t('Contact us:')}</p>&nbsp;
                <motion.a
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  href="mailto:hello@shareadesk.eu"
                >
                  hello@shareadesk.eu
                </motion.a>
              </InlineFlex>
            </Aside>
          </Flex>
        </FlexResponsiveSection>
      </Modal>
    </>
  )
}

export default ProspectModal
