/* eslint-disable react/require-default-props */
import styled from 'styled-components'
import { Property } from 'csstype'
import React from 'react'

import { useMobileMediaQuery, useDesktopMediaQuery } from '../../../hooks'

type FlexResponsiveSectionProps = {
  gap?: string
  isMobile?: boolean
  isDesktop?: boolean
}
const StyledFlexResponsiveSection = styled.section<FlexResponsiveSectionProps>`
  display: flex;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: ${props => props.gap || '2rem'};

  margin: ${props => (props.isMobile ? '2rem' : props.isDesktop ? '5rem' : '3rem 5rem')};
`

export const FlexResponsiveSection: React.FC<{ style?: React.CSSProperties }> = props => {
  const isMobile = useMobileMediaQuery()
  const isDesktop = useDesktopMediaQuery()
  return <StyledFlexResponsiveSection {...props} isMobile={isMobile} isDesktop={isDesktop} />
}

type FlexSectionProps = { flexDirection?: Property.FlexDirection }
export const FlexSection = styled(FlexResponsiveSection)<FlexSectionProps>`
  flex-direction: ${props => props.flexDirection || 'column'};
`

export const Flex = styled.div<{ flex?: string; maxWidth?: string; minWidth?: string }>`
  flex: ${props => (props.flex !== undefined ? props.flex : 1)} 0;
  width: 100%;
  max-width: ${props => (props.maxWidth !== undefined ? props.maxWidth : '37.5rem')};
  min-width: ${props => props.minWidth !== undefined && props.minWidth};
`

export const InlineFlex = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`
export const ColFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
