import styled, { ThemeProvider } from 'styled-components'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import GlobalStyles from './GlobalStyles'
import CookiesConsent from './CookiesConsent'
import Internationalization from './Internationalization'
import Landing from './pages/Landing'
import Header from './components/Header'
import theme from '../theme'
import { StoreProvider } from '../context/store'

const Background = styled.div`
  background-color: ${props => props.theme.colors.light};
`

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <StoreProvider>
      <GlobalStyles />
      <ToastContainer />

      <Background>
        <Header />

        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </Background>

      <CookiesConsent />
      <Internationalization />
    </StoreProvider>
  </ThemeProvider>
)

export default App
