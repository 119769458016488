import { useMediaQuery } from 'react-responsive'

export const useDesktopMediaQuery = (): ReturnType<typeof useMediaQuery> =>
  useMediaQuery({ minWidth: 992 })

export const useTabletAndBelowMediaQuery = (): ReturnType<typeof useMediaQuery> =>
  useMediaQuery({ maxWidth: 991 })

export const useTabletMediaQuery = (): ReturnType<typeof useMediaQuery> =>
  useMediaQuery({ minWidth: 650, maxWidth: 991 })

export const useMobileMediaQuery = (): ReturnType<typeof useMediaQuery> =>
  useMediaQuery({ maxWidth: 649 })

export const useMobileXSMediaQuery = (): ReturnType<typeof useMediaQuery> =>
  useMediaQuery({ maxWidth: 399 })
