import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_URI,
  cache: new InMemoryCache(),
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION,
})

export default client
