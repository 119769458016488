import { useMemo, useState, useImperativeHandle, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { ReactComponent as CloseSvg } from '@material-design-icons/svg/filled/close.svg'

import { IconButton } from '../Button'
import { useMobileMediaQuery, useDesktopMediaQuery } from '../../../hooks'

ReactModal.setAppElement('#root')

const AbsoluteContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

const Modal = forwardRef<ModalHanlde, ModalProps>(
  ({ children, style = {}, onRequestClose, ...props }, ref) => {
    const { t } = useTranslation()
    const [modalIsOpen, setIsOpen] = useState(false)
    const isMobile = useMobileMediaQuery()
    const isDesktop = useDesktopMediaQuery()
    const theme = useTheme()

    const customStyles = useMemo(() => {
      const { content, overlay, ...styles } = style
      return {
        overlay: {
          backgroundColor: 'rgba(7, 2, 2, 0.4)',
          ...overlay,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: 0,
          maxWidth: isDesktop ? '80%' : isMobile ? '100%' : '90%',
          maxHeight: '90%',
          transform: 'translate(-50%, -50%)',
          boxShadow: theme.boxShadow.current,
          backgroundColor: theme.colors.white,
          color: theme.colors.black,
          border: 0,
          borderRadius: theme.borderRadius.current,
          ...content,
        },
        ...styles,
      }
    }, [
      isDesktop,
      isMobile,
      style,
      theme.borderRadius,
      theme.boxShadow,
      theme.colors.black,
      theme.colors.white,
    ])

    const closeModal = (
      event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
    ) => {
      setIsOpen(false)
      onRequestClose?.(event)
    }

    useImperativeHandle(
      ref,
      () => ({
        openModal: () => {
          setIsOpen(true)
        },
        closeModal: () => {
          setIsOpen(false)
        },
        isOpen: modalIsOpen,
      }),
      [modalIsOpen]
    )

    return (
      <ReactModal {...props} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <AbsoluteContainer>
          <IconButton icon={CloseSvg} title={t('Close')} onClick={closeModal} />
        </AbsoluteContainer>
        {children}
      </ReactModal>
    )
  }
)

Modal.displayName = 'Modal'

export type ModalProps = Omit<ReactModalProps, 'isOpen'>

export type ModalHanlde = {
  openModal: VoidFunction
  closeModal: VoidFunction
  isOpen: boolean
}

export default Modal
