const theme = {
  colors: {
    primary: '#00C7A3', // primary zen
    secondary: '#2F2E41', // secondary deep blue
    light: '#F5F5F5', // light gray
    dark: '#2B2B2B', // stone black
    grey: '#888888', // subtle grey
    white: '#FFFFFF',
    black: '#000000',
    error: '#dc3545',
    success: '#00C7A3',
    warn: '#fcd000',
  },
  fonts: ['Inter, sans-serif', 'Open Sans, sans-serif'],
  fontSizes: {
    root: '16px',
    s: '0.813rem', // 13px
    sm: '0.9rem',
    m: '1rem', // 16px
    l: '1.5rem', // 24px
    xl: '2.5rem', // 40px
  },
  lineHeight: {
    s: '1rem',
    sm: '1.2rem',
    m: '1.5rem',
    l: '2rem',
    xl: '3rem',
    // fontSizes index key
    '0.813rem': '1rem',
    '0.9rem': '1.2rem',
    '1rem': '1.5rem',
    '1.5rem': '2rem',
    '2.875rem': '3rem',
  },
  borderRadius: {
    current: '0.625rem', // 10px
    input: '0.5rem', // 8px
    button: '0.5rem', // 8px
    link: '0.188rem', // 3px
  },
  boxShadow: {
    current: '0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.05)',
  },
  border: {
    button: '0.125rem', // 2px
    input: '0.125rem', // 2px
    link: '0.125rem', // 2px
  },
  padding: {
    button: '0.625rem 1.563rem', // 5px
    icon: '0.313rem', // 5px
    input: '0.625rem', // 10px
  },
}

export default theme
