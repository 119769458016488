import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

declare global {
  interface Window {
    cookieconsent: {
      initialise: (opts: Record<string, any>) => void
    }
  }
}

const CookiesConsent: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: theme.colors.white,
          text: theme.colors.secondary,
        },
        highlight: {
          background: theme.colors.light,
          text: theme.colors.primary,
        },
        button: {
          background: theme.colors.primary,
          text: theme.colors.white,
        },
      },
      theme: 'classic',
      position: 'bottom-left',
      content: {
        message: t(
          'This website uses cookies to ensure you get the best experience on our website.'
        ),
        dismiss: t('Got it!'),
        deny: t('Decline'),
        link: `${t('Learn more')} 🔗`,
        header: 'Cookies used on the website!',
        allow: t('Allow cookies'),
        href: 'https://www.cookiesandyou.com',
        close: '&#x274c;',
        policy: t('Cookie Policy'),
        target: '_blank',
      },
    })
  }, [
    t,
    theme.colors.black,
    theme.colors.light,
    theme.colors.primary,
    theme.colors.secondary,
    theme.colors.white,
  ])

  return null
}

export default CookiesConsent
