import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { UseFormRegisterReturn } from 'react-hook-form'
import styled from 'styled-components'

import { useMobileMediaQuery } from '../../../hooks'

import './style.css'

const Background = styled.div`
  background-color: ${props => props.theme.colors.light};
  border-radius: ${props => props.theme.borderRadius.input};
  display: flex;
`
const Field = styled(motion.fieldset)`
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.input};
  border: 0;
  margin: 0.125rem;
  padding: 0;
  overflow: visible;
  transition-property: color;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: ${props => props.theme.border.input} solid ${props => props.theme.colors.primary};
  }

  & > label {
    font-family: ${props => props.theme.fonts[0]};
    font-size: ${props => props.theme.fontSizes.s};
    color: ${props => props.theme.colors.grey};
    line-height: 1.3;
    padding: 0.625rem 1.563rem;
    border-radius: ${props => props.theme.borderRadius.input};

    &::before,
    &::after {
      border-radius: ${props => props.theme.borderRadius.input};
    }

    &::before {
      color: ${props => props.theme.colors.primary};
      background: ${props => props.theme.colors.white};
      box-shadow: ${props => props.theme.boxShadow.current};
    }
    &::after {
      color: ${props => props.theme.colors.primary};
      background: transparent;
    }
  }
`

const getId = (option: Option, name: string): string => `${name}${option?.value || ''}`

const SegmentedControl: React.FC<SegmentedControlProps> = ({
  options,
  name = 'segmentedControl',
  register = () => ({ name }),
  onClick,
}) => {
  const isMobile = useMobileMediaQuery()
  const registerReturn = register()

  useEffect(() => {
    const defaultOption = options.find(item => item.default === true)
    onClick?.(defaultOption?.value || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Background>
      <Field className="segmented-control">
        {options.map(option => (
          <motion.input
            key={option.value}
            value={option.value === null ? '' : option.value}
            type="radio"
            id={getId(option, registerReturn.name)}
            defaultChecked={option.default}
            disabled={option.disabled}
            {...registerReturn}
          />
        ))}
        {options.map(option => (
          <motion.label
            key={option.value}
            onClick={() => onClick?.(option.value)}
            onKeyPress={() => onClick?.(option.value)}
            htmlFor={getId(option, registerReturn.name)}
            title={option.title || option.label}
            role="presentation"
            data-value={isMobile ? option.shortLabel : option.label}
          >
            {isMobile ? option.shortLabel : option.label}
          </motion.label>
        ))}
      </Field>
    </Background>
  )
}

type Option = {
  label: string
  shortLabel: string
  title?: string
  value: React.Key | null
  default?: boolean
  disabled?: boolean
}

export interface SegmentedControlProps {
  register?: () => UseFormRegisterReturn
  name?: string
  options: Array<Option>
  onClick?: (value: React.Key | null) => void
}

export default SegmentedControl
