import styled from 'styled-components'

import Button, { ButtonProps } from './Button'

const Styled = styled(Button)`
  background-color: ${props => props.theme.colors.primary};
  border: ${props => props.theme.border.button} solid transparent;
  color: ${props => props.theme.colors.white};

  &:hover,
  &:focus,
  &:focus-within,
  &:focus-visible {
    background-color: ${props => props.theme.colors.white};
    border: ${props => props.theme.border.button} solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    outline: none;
  }
`

const PrimaryButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Styled {...props}>{children}</Styled>
)

export default PrimaryButton
