import { forwardRef } from 'react'
import styled from 'styled-components'

import Input, { InputProps } from './Input'

const Field = styled(Input)`
  background-color: ${props => props.theme.colors.white};
`

const PrimaryInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Field {...props} ref={ref} />
))

PrimaryInput.displayName = 'PrimaryInput'

export default PrimaryInput
