import { useEffect, useState, forwardRef } from 'react'
import { motion, HTMLMotionProps } from 'framer-motion'
import { FieldError } from 'react-hook-form'
import styled from 'styled-components'
import find from 'lodash/find'

import { ColFlex } from '../Layout'
import { useDesktopMediaQuery } from '../../../hooks'

const Field = styled(motion.input)<{ error?: boolean; $isDesktop: boolean }>`
  background-color: ${props => props.theme.colors.light};
  border-style: solid;
  border-color: transparent;
  border-width: ${props => props.theme.border.input};
  border-radius: ${props => props.theme.borderRadius.input};
  padding: ${props => props.theme.padding.input};
  width: ${props => props.width};

  ${props => {
    if (props.error) return `border-color: ${props.theme.colors.error} !important;`
    return null
  }}

  transition: 0.2s ease-in-out border-color;

  font-family: ${props => props.theme.fonts[0]};
  font-size: ${props => props.theme.fontSizes.s};
  color: rgb(51, 51, 51);
  caret-color: #333;
  line-height: 1.4;

  &::placeholder {
    color: #aaaaaa;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border-style: solid;
    border-color: ${props => props.theme.colors.primary};
    outline: none;
  }

  &[type='date'] {
    color: ${props => (props.value ? 'rgb(51, 51, 51)' : '#aaaaaa')};
  }
  &[type='date']:before {
    color: #aaaaaa;
    ${props =>
      props.$isDesktop &&
      `content: ${!props.value && props.placeholder ? `'${props.placeholder}' !important` : ''};`}
    margin-right: 0.5em;
  }

  &::-webkit-calendar-picker-indicator {
    fill: ${props => props.theme.colors.primary} !important;
    color: ${props => props.theme.colors.primary} !important;
  }
`
const Error = styled.p`
  color: ${props => props.theme.colors.error};
  font-weight: 300;
  padding-left: 0.625rem;
  margin: 0.313rem 0 0;
`

// @ts-expect-error type
const isMessageError = (error: ErrorProp): error is FieldError => !!error?.message
const getErrorMessage = (error?: ErrorProp): string | undefined => {
  if (!error) return undefined

  if (Array.isArray(error)) {
    return getErrorMessage(error[0])
  }
  if (isMessageError(error)) {
    return error.message
  }

  if (error && typeof error === 'object') {
    // @ts-expect-error type
    return find(error, (elem: FieldError) => isMessageError(elem))?.message
  }

  return undefined
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ onChange, value = '', error, required, placeholder, width, ...props }, ref) => {
    const [innerValue, setInnerValue] = useState(value)
    const isDesktop = useDesktopMediaQuery()

    useEffect(() => {
      setInnerValue(value)
    }, [value])

    return (
      <ColFlex style={{ maxWidth: width }}>
        <Field
          title={placeholder}
          placeholder={required && placeholder ? `${placeholder} *` : placeholder}
          width={width}
          {...props}
          error={!!error}
          ref={ref}
          value={innerValue}
          onChange={event => {
            setInnerValue(event.target.value)
            onChange?.(event)
          }}
          $isDesktop={isDesktop}
        />
        {error && <Error>{getErrorMessage(error)}</Error>}
      </ColFlex>
    )
  }
)

Input.displayName = 'Input'

type ErrorProp = FieldError | FieldError[] | Record<string, FieldError | FieldError[] | undefined>
export type InputProps = HTMLMotionProps<'input'> & {
  error?: ErrorProp
}

export default Input
