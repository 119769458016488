import { useRef } from 'react'
import { motion } from 'framer-motion'
import styled, { useTheme } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AccountSvg } from '@material-design-icons/svg/filled/account_circle.svg'
import { ReactComponent as LanguageSvg } from '@material-design-icons/svg/filled/language.svg'
import { ReactComponent as SearchSvg } from '@material-design-icons/svg/filled/search.svg'

import Logo from '../Logo'
import Input from '../Input'
import Button, { IconButton } from '../Button'
import LocalizationModal, { LocalizationHanlde } from '../../pages/Localization'
import { useDesktopMediaQuery, useMobileMediaQuery } from '../../../hooks'
import { useStoreContext } from '../../../context/store'

const Background = styled(motion.header)<{ isMobile?: boolean }>`
  background-color: ${props => props.theme.colors.white};

  position: fixed;
  width: 100vw;
  top: 0;
  padding: ${props => (props.isMobile ? '0.5rem' : '1.25rem')} 1.25rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  box-shadow: ${props => props.theme.boxShadow.current};
`
const FlexSection = styled(motion.section)<{ justify?: string; flex?: string | number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justify};
  flex: ${props => (props.flex !== undefined ? props.flex : 1)} 0;
`
const SafeTopMargin = styled.div<{ isMobile?: boolean }>`
  margin-top: ${props => (props.isMobile ? '6rem' : '8rem')};
`

const Header: React.FC = () => {
  const modalRef = useRef<LocalizationHanlde>(null)
  const {
    useProspectModalRef: [prospectModalRef],
  } = useStoreContext()
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const isDesktop = useDesktopMediaQuery()
  const isMobile = useMobileMediaQuery()

  const navigateToHome = () => {
    navigate('/')
  }

  return (
    <>
      <SafeTopMargin isMobile={isMobile} />

      <Background isMobile={isMobile}>
        <FlexSection flex={isDesktop ? 1 : 0}>
          {!isMobile && (
            <Logo
              onClick={() => {
                prospectModalRef?.openModal()
              }}
              title={isDesktop}
              style={{ margin: '0 1rem' }}
            />
          )}
          {isMobile && (
            <IconButton
              title={t('Search')}
              icon={SearchSvg}
              style={{
                backgroundColor: theme.colors.light,
                padding: theme.padding.input,
              }}
              onClick={() => {
                prospectModalRef?.openModal()
              }}
            />
          )}
        </FlexSection>

        {!isMobile && (
          <FlexSection justify="center" flex={isDesktop ? 1 : 0}>
            <Input
              placeholder={t('Start your search')}
              width={isDesktop ? '21.875rem' : '15.625rem'}
              style={{ paddingRight: '2.3rem' }}
              name="search"
              type="search"
            />
            <IconButton
              icon={SearchSvg}
              title={t('Search')}
              style={{ margin: '-0.313rem', right: '2.1rem' }}
              onClick={() => {
                prospectModalRef?.openModal()
              }}
            />
          </FlexSection>
        )}

        <FlexSection justify="flex-end">
          <Button
            style={{ borderRadius: '1.875rem' }}
            onClick={() => {
              prospectModalRef?.openModal()
            }}
          >
            {t('Become host')}
          </Button>

          <IconButton
            icon={LanguageSvg}
            title={t('Language')}
            style={{ margin: '0 0.313rem' }}
            color={theme.colors.grey}
            onClick={() => {
              modalRef.current?.openModal()
            }}
          />
          <IconButton
            title={t('Account')}
            icon={AccountSvg}
            onClick={() => {
              prospectModalRef?.openModal()
            }}
          />
        </FlexSection>
      </Background>

      <LocalizationModal ref={modalRef} />
    </>
  )
}

export default Header
