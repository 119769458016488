import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import { OSMData, SearchInput } from '../../components/Input'
import { FlexResponsiveSection, FlexSection, Flex } from '../../components/Layout'
import Logo from '../../components/Logo'
import ProspectModal from '../ProspectModal/ProspectModal'
import { ReactComponent as ConnectionSvg } from '../../../assets/undraw_connection_b-38-q.svg'
import { useDesktopMediaQuery } from '../../../hooks'
import { useStoreContext } from '../../../context/store'

const Strong = styled.strong`
  color: ${props => props.theme.colors.primary};
  font-weight: 800;
`

const Landing: React.FC = () => {
  const {
    useProspectModalRef: [prospectModalRef],
    useSearchLocation: [, setLocation],
  } = useStoreContext()
  const { t } = useTranslation()
  const isDesktop = useDesktopMediaQuery()

  return (
    <>
      {!isDesktop && (
        <FlexSection style={{ margin: 0 }}>
          <Logo fontSize="2rem" />
        </FlexSection>
      )}

      <FlexResponsiveSection>
        <Flex flex="2">
          <h1>{t('Find your next desk and connect.')}</h1>
          <article>
            <p>
              <Trans>
                <Strong>Share a desk</Strong> is a service designed to connect people willing to
                work remotely and share a work experience.
              </Trans>
            </p>
            <p>
              {t(
                "From home, share your workspace, it's time to collaborate, network, exchange best practices and earn some extra money."
              )}
            </p>
            <p>
              <Trans>
                <Strong>Lend</Strong> your desk or <Strong>search</Strong> for a desk.
              </Trans>
            </p>
          </article>
        </Flex>

        <Flex flex="3">
          <ConnectionSvg width="100%" height="auto" title={t('Connection')} />
        </Flex>
      </FlexResponsiveSection>

      <FlexSection>
        <Flex flex="1">
          <SearchInput
            onSubmit={() => {
              prospectModalRef?.openModal()
            }}
            location={{
              onChange: event => {
                setLocation(event.target.value as OSMData)
              },
            }}
          />
        </Flex>
        <Flex flex="1">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProspectModal />
          </div>
        </Flex>
      </FlexSection>
    </>
  )
}

export default Landing
