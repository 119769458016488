// Translation locales
import enTranslation from './en-GB/translation.json'
import frTranslation from './fr-FR/translation.json'

export const resources = {
  'en-GB': {
    translation: enTranslation,
  },
  'fr-FR': {
    translation: frTranslation,
  },
} as const
