import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LocationSvg } from '@material-design-icons/svg/filled/location_on.svg'
import { ReactComponent as DateSvg } from '@material-design-icons/svg/filled/date_range.svg'
import { ReactComponent as SearchSvg } from '@material-design-icons/svg/filled/search.svg'

import { PrimaryButton } from '../Button'
import { InputProps } from './Input'
import PrimaryInput from './PrimaryInput'
import LocationInput, { LocationInputProps } from './LocationInput'
import { useDesktopMediaQuery, useMobileMediaQuery } from '../../../hooks'

const Container = styled.form`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.current};
  box-shadow: ${props => props.theme.boxShadow.current};

  fill: ${props => props.theme.colors.primary};
  width: fit-content;
  margin: auto;
`

const SearchInput: React.FC<SearchInputProps> = ({ onSubmit, location, date }) => {
  const { t } = useTranslation()
  const isDesktop = useDesktopMediaQuery()
  const isMobile = useMobileMediaQuery()

  return (
    <Container>
      <LocationSvg style={{ marginLeft: '0.313rem' }} />
      <LocationInput
        width={isDesktop ? '18.75rem' : isMobile ? '7.5rem' : '12.5rem'}
        name="location"
        placeholder={t('Where')}
        {...location}
      />
      <DateSvg />
      <PrimaryInput
        width={isDesktop ? '12.5rem' : isMobile ? '7.5rem' : '9.375rem'}
        name="date"
        type="date"
        placeholder={t('When')}
        {...date}
      />
      <PrimaryButton
        type="submit"
        style={{ borderRadius: '0.375rem', padding: isMobile ? '0.625rem' : undefined }}
        title={t('Search')}
        onClick={event => {
          event.preventDefault()
          onSubmit?.()
        }}
      >
        {isMobile ? <SearchSvg height="2rem" style={{ margin: '-0.8rem -0.4rem' }} /> : t('Search')}
      </PrimaryButton>
    </Container>
  )
}

export interface SearchInputProps {
  onSubmit?: VoidFunction
  location?: LocationInputProps
  date?: InputProps
}

export default SearchInput
