import styled, { useTheme } from 'styled-components'
import { Property } from 'csstype'

import { ReactComponent } from '../../../assets/logo.svg'

const getLetterSpacing = (fontSize: Property.FontSize) =>
  `-${parseFloat(fontSize as string) * 0.0753}rem`
const getLineHeight = (fontSize: Property.FontSize) =>
  `${parseFloat(fontSize as string) * 1.333}rem`

type FigureProps = {
  color?: string
  fontSize?: Property.FontSize
  onClick?: VoidFunction
}
const Figure = styled.figure<FigureProps>`
  flex-shrink: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  color: ${props => props.color || props.theme.colors.primary};

  figcaption {
    font-weight: 700;
    font-style: normal;
    font-family: ${props => props.theme.fonts[1]};
    font-size: ${props => props.fontSize || props.theme.fontSizes.l};

    letter-spacing: ${props => (props.fontSize ? getLetterSpacing(props.fontSize) : '-0.113rem')};
    line-height: ${props =>
      props.fontSize ? getLineHeight(props.fontSize) : props.theme.lineHeight.l};
    text-align: left;
  }

  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  user-select: none;

  &:hover {
    ${props => !!props.onClick && `filter: drop-shadow(0.125rem 0.125rem rgba(0, 0, 0, 0.05));`}
  }
`
const Icon = styled(ReactComponent)<{ color?: Property.Color; $hasTitle?: boolean }>`
  margin-right: ${props => props.$hasTitle && '0.6rem'};
  fill: ${props => props.color || props.theme.colors.primary};
`

const Logo: React.FC<LogoProps> = ({ color, onClick, fontSize, style, title = true }) => {
  const theme = useTheme()

  return (
    <Figure color={color} onClick={onClick} fontSize={fontSize} title="Shareadesk" style={style}>
      <Icon
        height={fontSize ? getLineHeight(fontSize) : theme.lineHeight.l}
        width="auto"
        color={color}
        $hasTitle={title}
      />
      {title && <figcaption>shareadesk</figcaption>}
    </Figure>
  )
}

export interface LogoProps {
  color?: Property.Color
  title?: boolean
  onClick?: VoidFunction
  fontSize?: Property.FontSize
  style?: React.CSSProperties
}

export default Logo
